import { useFetchProfessions } from '@sekoia/shared/requests/atlas/useFetchProfessions';
import { ProfessionResponse } from '@sekoia/shared/requests/nswagClients/AtlasClient';
import { useProfileContext } from 'context/Profile';
import LoadingScreen from 'components/LoadingScreen';
import React, { useContext } from 'react';
import { useFetchDashboards } from '@sekoia/shared/hooks/requests/dashboards/useFetchDashboards';
import { DashboardResponse } from '@sekoia/shared/requests/nswagClients/DashboardsClient';

interface AppContext {
  professions: ProfessionResponse[];
  dashboards: DashboardResponse[] | undefined;
}

const AppContext = React.createContext<AppContext | null>(null);

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw Error(`No app context found!`);
  }

  return appContext;
};

export const AppProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  const currentProfile = useProfileContext();
  const { data: professions, fetchStatus, status } = useFetchProfessions(currentProfile.cultureInfoCode);
  const { data, fetchStatus: fetchStatusDashboards } = useFetchDashboards();


  if (fetchStatus === 'fetching' || fetchStatusDashboards === 'fetching') return <LoadingScreen />;
  if (status === 'error' || !professions) throw new Error('professions fetch failed');

  return <AppContext.Provider value={{ professions, dashboards: data }}>{children}</AppContext.Provider>;
};
