import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'context/Auth/AuthProvider';
import BaseErrorView from 'views/base/BaseErrorView';
import { Bootstrap } from 'Bootstrap';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LicenseInfo } from '@mui/x-license';
import * as am5 from '@amcharts/amcharts5';

LicenseInfo.setLicenseKey(
  '8c3b0640cc4e701b71920fa0b41365f0Tz05NTc5OSxFPTE3NTQ3MjA1OTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);
am5.addLicense('AM5C353218934');

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        webkitTouchCallout: 'none',
        webkitTapHighlightColor: 'transparent',
      },
      '*:focus': {
        outline: 'none',
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.print': {
        display: 'none!important',
      },
      '@media print': {
        '*': {
          overflow: 'unset!important',
        },
        '.no-print': {
          display: 'none!important',
        },
        '.print': {
          display: 'initial!important',
        },
      },

      '.plan-print': {
        pageBreakAfter: 'always',
        fontFamily: 'Open Sans,Arial,Helvetica,Sans-Serif',
      },

      '.plan-print p': {
        margin: 10,
        whiteSpace: 'pre-line',
        fontSize: 14,
      },

      '.plan-print .plan': {
        pageBreakAfter: 'always',
      },

      '.plan-print .log-item': {
        paddingBottom: 10,
        marginLeft: 10,
        pageBreakInside: 'avoid',
        fontSize: 15,
      },

      '.plan-print .log-item-date': {
        marginTop: 2,
        fontSize: 13,
      },

      '.plan-print blockquote': {
        margin: 0,
        whiteSpace: 'pre-line',
        padding: '10px 20px',
        border: 0,
        borderLeft: '5px solid #eee',
      },

      '.ql-editor': {
        minHeight: 180,
      },
      '.MuiDialogContent-root .quill': {
        position: 'absolute',
        bottom: 8,
        top: 40,
        right: 0,
        left: 0,
      },
      '.MuiDialogContent-root .ql-editor': {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
      },
      '.MuiDialogContent-root .ql-container': {
        border: 'none',
      },
      '.ql-toolbar.ql-snow': {
        border: 'none',
      },
    },
  }),
);

// Clarity snippet
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(function (c: any, l: any, a: any, r: any, i: any) {
  c[a] =
    c[a] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (c[a].q = c[a].q || []).push(arguments);
    };
  const t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  const y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
})(window, document, 'clarity', 'script', 'j8t7sx9vdd');
// End Clarity snippet

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(['global']);
  useStyles();

  return (
    <BrowserRouter>
      <AuthProvider>
        <BaseErrorView
          title={t('fullscreenErrorTitle')}
          description={t('fullscreenErrorDescription')}
          buttonText={t('tryAgain')}
        >
          <Bootstrap />
        </BaseErrorView>
      </AuthProvider>
    </BrowserRouter>
  );
};
